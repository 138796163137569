import React, { useState } from "react"

import Context from "../context/StoreContext"

const ContextProvider = ({ children }) => {
  // Initials state
  const initialState = {
    shared: false,
  }

  // State
  const [store, updateStore] = useState(initialState)

  return (
    <Context.Provider
      value={{
        store,
        activate: () =>
          updateStore(prevState => ({
            ...prevState,
            shared: true,
          })),
        close: () =>
          updateStore(prevState => ({
            ...prevState,
            shared: true,
          })),
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default ContextProvider
