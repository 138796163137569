import { createGlobalStyle } from "styled-components"

// Theme
export const theme = {
  color: {
    primary: "#4B92DB",
    secondary: "#53ACA6",
    backgroundSectionOne: "#E3F2FB",
    light: "#D1F2EF",
    black: "#191919",
    white: "#FFFFFF",
    dark: "#434343",
    darker: "#272727",
  },
  gradient: {
    green:
      "linear-gradient(112deg, rgba(42,136,77,1) 0%, rgba(0,155,58,1) 100%)",
    blue:
      "linear-gradient(112deg, rgba(75,146,219,1) 0%, rgba(32,97,163,1) 100%)",
    minerals:
      "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(190,190,161,1) 100%)",
    tanker: "linear-gradient(180deg, rgb(15,16,35) 0%, rgb(35,35,35) 100%)",
  },
  shadow: {
    card: "0 2px 4px rgba(0, 0, 0, 0.5)",
  },
}

// Breakpoints
export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
  xxl: 1920,
}

// Dimensions
export const dimensions = {
  contentWidth: "1170px",
}

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    background: ${theme.color.white};

    @media (min-width: ${breakpoints.xl}px){
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin: 0 0 24px 0;
    padding: 0;
    line-height: 1.2;
  }
  h1 {
    font-size: 36px;

    @media (min-width: ${breakpoints.xl}px){
      font-size: 48px;
    }
  }
  h2 {
    font-size: 24px;

    @media (min-width: ${breakpoints.xl}px){
      font-size: 32px;
    }
  }
  h3 {
    font-size: 22px;

    @media (min-width: ${breakpoints.xl}px){
      font-size: 24px;
    }
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 12px;
  }

  p {
    margin: 0 0 24px 0;
    line-height: 1.2

    @media (min-width: ${breakpoints.xl}px){
      margin-bottom: 32px;
    }
  }

  img {
    max-width: 100%;
    margin: 0 0 24px 0;
    padding: 0;
  }

  mark {
    background-color: #ff0;
    color: #000;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
    margin: 0;
    box-sizing : border-box;
  }

  a[href^="tel"]{
    color:inherit;
    text-decoration:none;
  }
`
