import styled from "styled-components"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.footer`
  color: ${({ theme }) => theme.color.white};
  background: rgb(42, 136, 77);
  background: ${({ theme }) => theme.gradient.green};
  display: flex;
  flex-direction: column;
  padding: 200px 20px;

  @media (max-width: ${breakpoints.s}px) {
    padding: 64px 20px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CollabLogoGrid = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`

export const CollabLogo = styled.img`
  max-height: 100px;
  margin: 16px;

  @media (max-width: ${breakpoints.s}px) {
    max-width: 160px;
    max-height: none;
  }
`

export const CollabText = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 700px;
  text-align: center;
`

export const Logo = styled.img`
  height: 42px;
  margin-bottom: 32px;
`

export const Icon = styled.img`
  height: 56px;
`

export const MadeLink = styled.a`
  color: white;
  font-size: 16px;
  line-height: 20px;
  padding: 0 4px;
  text-decoration: none;
`
