import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import { Logo, Section, Container, Icon, CollabText, CollabLogoGrid, CollabLogo, MadeLink } from "./styles"

import CannonIcon from "../../../assets/cannon.svg"
import ArmeLogo from "../../../assets/armemuseum-logo.svg"
import miliseumLogo from "../../../assets/collaborators/milliseum.png"
import invidzonenLogo from "../../../assets/collaborators/invidzonen.png"
import veteranLogo from "../../../assets/collaborators/sverigesveteranforbund.png"
import uppsalaLogo from "../../../assets/collaborators/uppsalauniversitet.png"

const collaborators = [
  { id: 1, src: uppsalaLogo, alt: "Uppsala Universitet" },
  { id: 2, src: veteranLogo, alt: "Sveriges Veteranförbund" },
  { id: 3, src: miliseumLogo, alt: "Miliseum" },
  { id: 4, src: invidzonenLogo, alt: "Invidzonen" },
]

const Footer = () => {
  const intl = useIntl()
  return (
    <Section>
      <Container>
        <Icon
          src={CannonIcon}
          alt={intl.formatMessage({ id: "footer.cannon_alt" })}
        />
        <Logo
          src={ArmeLogo}
          alt={intl.formatMessage({ id: "footer.logo_alt" })}
        />
        <CollabText>
          {intl.formatMessage({ id: "footer.collaborations" })}
        </CollabText>
        <CollabLogoGrid>
          {collaborators.map(item => <CollabLogo key={item.id} src={item.src} alt={item.alt} />)}
        </CollabLogoGrid>
        <CollabText>
          {intl.formatMessage({ id: "footer.made_by" })}
          <MadeLink href="https://designworld.co">
            Designworld
          </MadeLink>
          {'&'}
          <MadeLink href="https://www.expology.com">
            Expology
          </MadeLink>
        </CollabText>
      </Container>
    </Section>
  )
}

export default Footer
