import React from "react"

// Available types
export const Type = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
}

const Heading = ({ children, type }) => {
  switch (type) {
    case Type.h5: {
      return <h5>{children}</h5>
    }
    case Type.h4: {
      return <h4>{children}</h4>
    }
    case Type.h3: {
      return <h3>{children}</h3>
    }
    case Type.h2: {
      return <h2>{children}</h2>
    }
    default: {
      return <h1>{children}</h1>
    }
  }
}

export default Heading
